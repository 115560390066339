import React from "react";
import { Header } from "components";
import { Invalid } from "static/images"
import { Box } from "@mui/material";

const InvalidPage = () => {

    return (
        <>
            <Header />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 150px)', // Garante que o Box ocupe 100% da altura da tela
                }}
            >
                <img src={Invalid} alt="" />
                <p style={{ marginTop: '16px'}}>Solicitação inválida ou expirada, solicite uma nova autorização.</p>
            </Box>
        </>
    )
}

export default InvalidPage