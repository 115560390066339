export function ellipsis(input: string, maxLength: number): string {

    // Se a string original já é menor ou igual ao comprimento máximo, retorná-la sem alterações
    if (input.length <= maxLength) {
        return input;
    }

    // Calcular o comprimento máximo disponível para a string original
    const availableLength = maxLength - 3; // Reservar 3 caracteres para as reticências
    return input.slice(0, availableLength) + '...';
}