import React from "react";
import { Header } from "components";
import { TabMenu } from "components/TabMenu/TabMenu";
import './styles.scss';

const ResumePage = () => {

    return (
        <>
            <Header />
            <div className="resume-container">
                {/* <div className="resume-header">
                    <DownloadButton />
                </div> */}
                <div className="resume-body">
                    <TabMenu />
                </div>
            </div>
        </>
    )
}

export default ResumePage