import { AxiosResponse } from "axios";
import { api } from "./api";

export const getResume = (meetingId: string): Promise<AxiosResponse> => {
    return api.get(`/resume?meeting_id=${meetingId}`)
} 

export const getResumeHistory = (meetingId: string, page: number): Promise<AxiosResponse> => {
    return api.get(`/resume/history?meeting_id=${meetingId}&page=${page}`)
} 

export const getResumeSpeeches = (meetingId: string): Promise<AxiosResponse> => {
    return api.get(`/resume/speeches?meeting_id=${meetingId}`)
} 

export const createMagicQuestion = (meetingId: string, question: string): Promise<AxiosResponse> => {
    return api.post('/question', {question, meeting_id: meetingId})
}

export const getMagicQuestion = (questionId: string): Promise<AxiosResponse> => {
    return api.get(`/question?question_id=${questionId}`)
}