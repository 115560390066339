import React from "react";
import { Header } from "components";
import { Success } from "static/images";
import { Box } from "@mui/material";

const SuccessPage = () => {

    return (
        <>
            <Header />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 150px)', // Garante que o Box ocupe 100% da altura da tela
                }}
            >
                <img src={Success} alt="" />
                <p style={{ marginTop: '16px'}}>Dispositivo autorizado com sucesso!</p>
            </Box>
        </>
    )
}

export default SuccessPage