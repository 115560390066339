import React, { useState } from "react";
import { Logo, Email, Link } from "static/images";
import "./styles/tabmenu.scss";
import { Alert, Snackbar } from "@mui/material";

export const Header = () => {
    const [open, setOpen] = useState(false);

    const handleClick = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        // Copia a URL atual da página
        navigator.clipboard.writeText(window.location.href)
            .then(() => {
                setOpen(true); // Exibe o alerta quando a URL é copiada com sucesso
            })
            .catch((err) => {
                console.error("Erro ao copiar URL: ", err);
            });
    };
    const handleClose = (event: any, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false); // Fecha o alerta
    };
    return <div className="header resume-container">
        <div className="header-container">
            <img height="33px" width="auto" src={Logo} />

            <div className="header-ctas">
                <a className="link" href="#" onClick={handleClick}><img src={Link} />Copiar link</a>
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert severity="success" sx={{ width: '100%' }}>
                        URL copiada para a área de transferência!
                    </Alert>
                </Snackbar>
            </div>
        </div>
    </div>
}