import React from 'react';
import { Box } from '@mui/material';

import { ResumePage } from './Tabs';
import "../styles/tabmenu.scss"



export const TabMenu = () => {

    return (
        <Box className="tab-menu" sx={{ width: '100%' }}>
            <ResumePage />
        </Box>
    );
}