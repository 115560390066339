import React from 'react'
import { createHashRouter } from 'react-router-dom'
import ResumePage from './pages/resume/Index'
import SuccessPage from 'pages/success'
import InvalidPage from 'pages/invalid'

export const routes = createHashRouter([
  {
    path: '/meeting/:meetindId/transcript',
    element: <ResumePage />,
    errorElement: <div>Error</div>
  },
  {
    path: '/sucesso',
    element: <SuccessPage />,
    errorElement: <div>Error</div>
  },
  {
    path: '/solicitacao-invalida',
    element: <InvalidPage />,
    errorElement: <div>Error</div>
  },
  {
    path: '/',
    element: <div>DIV</div>
  }
])
